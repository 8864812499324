<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card class="d-flex flex-column fill-height">
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Kontrollmodus</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            dark
            text
            :disabled="!currentOrder"
            :href="currentOrder ? `https://svg.us.accelery.host/order/configurator?orderId=${currentOrder.orderId}` : '#'"
            target="_blank"
          >
            <v-icon left>mdi-pencil</v-icon>
            Bearbeiten
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-sheet class="flex-grow-1 grey darken-4">
        <v-container v-if="currentOrder" fluid class="fill-height pa-0">
          <v-row no-gutters class="fill-height">
            <!-- Linke Seite - Großes Bild -->
            <v-col cols="9" class="d-flex align-center justify-center">
              <v-img
                :src="currentProduct?.design?.poster"
                contain
                max-height="80vh"
                class="rounded-lg"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="white"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>

            <!-- Rechte Seite - Bestelldetails -->
            <v-col cols="3" class="white">
              <v-sheet class="fill-height d-flex flex-column">
                <!-- Scrollbarer Inhaltsbereich -->
                <div class="overflow-y-auto flex-grow-1 pa-4">
                  <!-- Bestellnummer und Status -->
                  <div class="mb-6">
                    <h2 class="text-h5 mb-2">Bestellung #{{ currentOrder.orderId }}</h2>
                    <v-chip
                      :color="currentOrder.isChecked ? 'success' : 'grey'"
                      small
                    >
                      {{ currentOrder.isChecked ? 'Kontrolliert' : 'Nicht kontrolliert' }}
                    </v-chip>
                  </div>
                  
                  <!-- Kundeninfo -->
                  <v-list dense class="mb-4 transparent">
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>mdi-account</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ currentOrder.customer.firstName }} {{ currentOrder.customer.lastName }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>mdi-map-marker</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ currentOrder.customer.adress }}</v-list-item-title>
                        <v-list-item-subtitle>{{ currentOrder.customer.zip }} {{ currentOrder.customer.city }}, {{ currentOrder.customer.country }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>mdi-clock-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ new Date(currentOrder.created).toLocaleString() }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>

                  <!-- Produktdetails -->
                  <div class="mb-4">
                    <h3 class="text-subtitle-1 font-weight-bold mb-2">{{ currentProduct?.title }}</h3>
                    <v-chip
                      small
                      class="mr-2 mb-2"
                      color="primary"
                      outlined
                    >
                      <v-icon left small>mdi-package-variant</v-icon>
                      {{ currentProduct?.sku }}
                    </v-chip>
                    <v-chip
                      small
                      class="mr-2 mb-2"
                      color="secondary"
                      outlined
                    >
                      <v-icon left small>mdi-numeric</v-icon>
                      Menge: {{ currentProduct?.qty }}
                    </v-chip>
                  </div>

                  <!-- Transparenzstatus -->
                  <v-chip
                    v-if="currentProduct?.design"
                    small
                    class="mb-4"
                    :color="getTransparencyColor(currentProduct.design)"
                    outlined
                  >
                    <v-icon left small>{{ getTransparencyIcon(currentProduct.design) }}</v-icon>
                    <span v-if="!currentProduct.design.transparencyAnalyzed">Wird analysiert...</span>
                    <span v-else>
                      {{ currentProduct.design.hasTransparencies ? 'Transparenzen' : 'Keine Transparenzen' }}
                    </span>
                  </v-chip>
                </div>

                <!-- Aktionsbuttons -->
                <v-sheet class="pa-4" elevation="2">
                  <v-btn
                    color="error"
                    block
                    class="mb-2"
                    @click="skipOrder"
                    height="44"
                  >
                    <v-icon left>mdi-close</v-icon>
                    Überspringen
                  </v-btn>
                  <v-btn
                    color="success"
                    block
                    @click="approveOrder"
                    height="44"
                  >
                    <v-icon left>mdi-check</v-icon>
                    Als kontrolliert markieren
                  </v-btn>
                </v-sheet>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>

        <v-container v-else fluid class="fill-height">
          <v-row class="fill-height" align="center" justify="center">
            <v-col cols="12" class="text-center white--text">
              <v-icon size="64" color="white">mdi-check-all</v-icon>
              <h2 class="text-h5 white--text mt-4">Keine weiteren Bestellungen zu kontrollieren</h2>
              <v-btn color="primary" class="mt-4" @click="closeDialog">
                Zurück zur Übersicht
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </v-card>
  </v-dialog>
</template>

<script>
import DesignService from '../../services/DesignService';

export default {
  name: 'OrderCheckDialog',
  
  props: {
    value: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    dialog: false,
    orders: [],
    currentIndex: 0,
    loading: false,
    error: null
  }),

  computed: {
    currentOrder() {
      return this.orders[this.currentIndex] || null;
    },
    currentProduct() {
      return this.currentOrder?.products[0] || null;
    }
  },

  watch: {
    value(newVal) {
      this.dialog = newVal;
      if (newVal) {
        this.fetchOrders();
      }
    },
    dialog(newVal) {
      this.$emit('input', newVal);
    }
  },

  methods: {
    getTransparencyIcon(design) {
      if (!design.transparencyAnalyzed) return 'mdi-progress-clock';
      return design.hasTransparencies ? 'mdi-alert' : 'mdi-check-circle';
    },

    getTransparencyColor(design) {
      if (!design.transparencyAnalyzed) return 'grey';
      return design.hasTransparencies ? 'warning' : 'success';
    },

    async fetchOrders() {
      this.loading = true;
      try {
        const response = await DesignService.getOrdersExtended({
          ...this.filters,
          page: 1,
          limit: 100
        });
        this.orders = response.data.orders;
        this.currentIndex = 0;
      } catch (err) {
        this.error = err.message;
      } finally {
        this.loading = false;
      }
    },

    async approveOrder() {
      if (!this.currentOrder) return;
      
      try {
        await DesignService.updateOrderCheckedStatus(this.currentOrder.orderId, true);
        this.nextOrder();
      } catch (err) {
        this.error = err.message;
      }
    },

    skipOrder() {
      this.nextOrder();
    },

    nextOrder() {
      if (this.currentIndex < this.orders.length - 1) {
        this.currentIndex++;
      } else {
        this.closeDialog();
      }
    },

    closeDialog() {
      this.dialog = false;
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.v-card {
  display: flex;
  flex-direction: column;
}

.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform .3s ease-in-out;
}

/* Verhindert das Scrollen des Hauptbildes */
.v-img {
  max-width: 95%;
  margin: 0 auto;
}

/* Anpassungen für die rechte Seitenleiste */
.overflow-y-auto {
  overflow-y: auto;
}

.transparent {
  background-color: transparent !important;
}
</style> 